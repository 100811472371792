import React from 'react'

import InvestorsImg from '../../images/investors/investors.jpg';
import PageHeader from "../global/pageheader";


export default function FinancialReports() {

    return (
        <div className="container">
            <PageHeader img={InvestorsImg} head="Investors"></PageHeader>
            <div className="card mt-2 mb-2" >
                <div className="card-body">
                    <a href="/financials/fy_310324.pdf">Financial Statement for the half and financial year ended 31.03.24</a>
                </div>
            </div>
            <div className=' card row mx-auto text-center my-5'>
                <div className="m-2 ">
                    <p>For more information kindly contact</p>
                    <p className='fw-bold'>Ms Supriya Shishodia</p>
                    <p> Company Secretary </p>
                    <p> Email : cs@sonamachinery.com</p>
                </div>
            </div>
        </div>
    );
}