import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import InvestorsImg from '../../images/investors/investors.jpg';
import PageHeader from "../global/pageheader";

export default function Committees() {

    return (
        <div className="container-fluid">
            <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="Committees"></PageHeader>
            <div className="card mt-2 mb-2 shadow" >
                <div className="card-header">Audit Committee</div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Designation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Naman Jain</td>
                                    <td>Non- Executive, Independent Director</td>
                                    <td>Chairperson & Member</td>
                                </tr>
                                <tr>
                                    <td>Preet Kumar</td>
                                    <td>Non- Executive, Independent Director</td>
                                    <td>Member</td>
                                </tr>
                                <tr>
                                    <td>Shweta Baisla</td>
                                    <td>Executive, WTD & CFO</td>
                                    <td>Member</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div className="card mt-2 mb-2 shadow">
                <div className="card-header">Nomination and Remuneration Committee</div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Designation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Preet Kumar</td>
                                    <td>Non- Executive, Independent Director</td>
                                    <td>Chairperson & Member</td>
                                </tr>
                                <tr>
                                    <td>Naman Jain</td>
                                    <td>Non- Executive, Independent Director</td>
                                    <td>Member</td>
                                </tr>
                                <tr>
                                    <td>Vasu Naren</td>
                                    <td>Executive, Chairman & Managing Director</td>
                                    <td>Member</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="card mt-2 mb-2 shadow">
                <div className="card-header">Stakeholder and Relationship Committee</div>
                <div className="card-body">
                    <div className="mt-2 mb-2" >
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Designation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Naman Jain</td>
                                    <td>Non- Executive, Independent Director</td>
                                    <td>Chairperson & Member</td>
                                </tr>
                                <tr>
                                    <td>Preet Kumar</td>
                                    <td>Non- Executive, Independent Director</td>
                                    <td>Member</td>
                                </tr>
                                <tr>
                                    <td>Akash Kumar Bansal</td>
                                    <td>Non- Executive, Independent Director</td>
                                    <td>Member</td>
                                </tr>
                            </tbody>
                        </table>
                    </div></div>
            </div>

        </div>
    );
}